(function () {
	'use strict';
	angular
		.module('filters')
		.controller('FiltersPopoverCtrl', [
			'$scope',
			'manageFilters',
			'utilities',
			FiltersPopoverCtrl,
		])
		.controller('FiltersCtrl', [
			'$scope',
			'$rootScope',
			'$timeout',
			'utilities',
			'seedcodeCalendar',
			'manageFilters',
			'environment',
			FiltersCtrl,
		]);

	function FiltersPopoverCtrl($scope, manageFilters, utilities) {
		$scope.changePanel = changePanel;

		$scope.cancelEdit = cancelEdit;

		$scope.filterIsEditable = manageFilters.filterIsEditable;

		$scope.filterIsDynamic = manageFilters.filterIsDynamic;

		$scope.updateStatus = function (operation, statusObj, fieldMap) {
			if (!statusObj.name) {
				return;
			}
			if (operation === 'edit' && !statusObj.id) {
				operation = 'create';
			}
			var successCallback = function () {
				$scope.$evalAsync(function () {
					$scope.popover.config.show = false; // Hide the popover
				});
			};

			manageFilters.updateStatus(
				operation,
				statusObj,
				fieldMap,
				successCallback
			);
		};

		$scope.updateResource = function (
			operation,
			resourceItem,
			resourceEdit,
			fieldMap
		) {
			if (!resourceEdit.name) {
				return;
			}

			var originalResourceEdit =
				manageFilters.createFilterEdit(resourceItem);
			var changes = {};

			for (var property in fieldMap) {
				if (resourceEdit[property] !== originalResourceEdit[property]) {
					changes[property] = resourceEdit[property];
				}
			}

			if (operation === 'edit' && !resourceEdit.id) {
				operation = 'create';
			}
			var successCallback = function () {
				$scope.$evalAsync(function () {
					$scope.popover.config.show = false; // Hide the popover
				});
			};

			manageFilters.updateResource(
				operation,
				resourceEdit,
				fieldMap,
				successCallback
			);
		};

		$scope.toggleConfirmDelete = function () {
			$scope.confirmDelete = !$scope.confirmDelete;
		};

		$scope.updateFolder = function (operation, folderObject, fieldMap) {
			if (folderObject.type === 'status-folder') {
				$scope.updateStatus(operation, folderObject, fieldMap);
			} else {
				$scope.updateResource(operation, folderObject, fieldMap);
			}
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		function changePanel(name) {
			if (name === 'edit') {
				$scope.filter.panel = 'edit';
				$scope.filter.fade = true;
			} else {
				$scope.filter.panel = 'view';
				$scope.filter.fade = false;
			}
		}

		function cancelEdit(filterItem, filterEdit) {
			// If this is a cancel from a new filter then close popover
			if ($scope.filter.create) {
				$scope.popover.config.show = false;
				return;
			}

			changePanel('view');
			// Revert edit object
			var cleanEdit = manageFilters.createFilterEdit(filterItem);
			for (var property in cleanEdit) {
				filterEdit[property] = cleanEdit[property];
			}
		}
	}

	function FiltersCtrl(
		$scope,
		$rootScope,
		$timeout,
		utilities,
		seedcodeCalendar,
		manageFilters,
		environment
	) {
		$scope.calendar = {};

		$scope.calendar.config = seedcodeCalendar.get('config');
		$scope.calendar.isPhone = environment.isPhone;
		//Load statuses from our model and watch for future updates
		$scope.calendar.statuses = seedcodeCalendar.get('statuses');
		$scope.calendar.resourcesAll = seedcodeCalendar.get('resources');
		$scope.calendar.resources = $scope.calendar.config.resourcesFiltered
			? $scope.calendar.config.resourcesFiltered
			: seedcodeCalendar.get('resources');

		$scope.calendar.hasResourceFilter =
			$scope.calendar.config.resourceListFilter;

		$scope.calendar.projects = seedcodeCalendar.get('projects');
		$scope.calendar.contacts = seedcodeCalendar.get('contacts');
		$scope.listFilters = seedcodeCalendar.get('listFilters');

		$scope.calendar.resourcesNested = $scope.calendar.config
			.resourcesFiltered
			? buildNestedDirectory($scope.calendar.config.resourcesFiltered)
			: buildNestedDirectory(seedcodeCalendar.get('resources'));

		$scope.calendar.statusesNested = buildNestedDirectory(
			seedcodeCalendar.get('statuses')
		);

		$scope.filterItemDropped = filterItemDropped;
		$scope.filterItemDragged = filterItemDragged;

		$scope.collapseFilter = collapseFilter;

		$scope.filterIsEditable = manageFilters.filterIsEditable;
		$scope.filterIsDynamic = manageFilters.filterIsDynamic;
		$scope.filterIsNoFilterLabel = manageFilters.filterIsNoFilterLabel;

		//Initialize text filters
		$scope.textFilters = {
			text: seedcodeCalendar.get('textFilters'),
			focus: true,
		};

		$scope.$on('textFilters', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.textFilters =
					seedcodeCalendar.get('textFilters');
				$scope.textFilters = {
					text: seedcodeCalendar.get('textFilters'),
					focus: true,
				};
			});
		});

		$scope.$on('resourceListFilter', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.config.resourceListFilter =
					seedcodeCalendar.get('resourceListFilter');
			});
		});

		$scope.$on('statuses', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.statuses = seedcodeCalendar.get('statuses');
				$scope.calendar.statusesNested = buildNestedDirectory(
					seedcodeCalendar.get('statuses')
				);
			});
		});

		$scope.$on('resources', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.resources = $scope.calendar.config
					.resourcesFiltered
					? $scope.calendar.config.resourcesFiltered
					: seedcodeCalendar.get('resources');

				$scope.calendar.resourcesNested = $scope.calendar.config
					.resourcesFiltered
					? buildNestedDirectory(
							$scope.calendar.config.resourcesFiltered
						)
					: buildNestedDirectory(seedcodeCalendar.get('resources'));
			});
		});

		$scope.$on('resourcesFiltered', function (e, filteredResources) {
			$scope.$evalAsync(function () {
				$scope.calendar.resources = filteredResources || [];
				$scope.calendar.resourcesNested =
					buildNestedDirectory(filteredResources);
				$scope.calendar.hasResourceFilter =
					$scope.calendar.config.resourceListFilter &&
					seedcodeCalendar.get('resources').length !==
						$scope.calendar.resources.length;
			});
		});

		$scope.$on('projects', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.projects = seedcodeCalendar.get('projects');
			});
		});

		$scope.$on('contacts', function () {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.calendar.contacts = seedcodeCalendar.get('contacts');
			});
		});

		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';

		$scope.textFilterLongDelay = textFilterLongDelay;

		$scope.textFilter = textFilter;

		$scope.resourceListFilter = resourceListFilter;

		$scope.clearFilterListFilter = clearFilterListFilter;

		function buildNestedDirectory(list, filterFunction) {
			var config = seedcodeCalendar.get('config');
			var nestedResult = [];
			var listSectionCount = -1;
			var lastFolderID;
			var forceNested;

			if (!list) {
				return;
			}

			// Set scope var to interact with directive to prevent automatic scrolling
			// This is used because there are situations where the digest cycle updating data
			// could result in a scrolled sidebar
			$scope.calendar.preventFilterScroll = true;

			for (var i = 0; i < list.length; i++) {
				if (
					lastFolderID !== list[i].folderID ||
					listSectionCount < 0 ||
					forceNested
				) {
					listSectionCount++;
					nestedResult[listSectionCount] = {
						items: [],
						groupingID: list[i].folderID ? list[i].folderID : '',
					};
				}

				nestedResult[listSectionCount].items.push(list[i]);

				// Set last folder ID to compare on next loop
				lastFolderID = list[i].isFolder
					? list[i].folderID + '-folder'
					: list[i].folderID;

				if (forceNested) {
					forceNested = false;
				}

				if (list[i].name === config.noFilterLabel) {
					forceNested = true;
				}
			}

			return nestedResult;
		}

		function filterItemDragged(type, startContainerIndex, startIndex) {
			var itemType;

			var items = $scope.calendar[type + 'Nested'];

			var draggedItem = items[startContainerIndex].items[startIndex];

			if (
				draggedItem &&
				draggedItem.isFolder &&
				draggedItem.status &&
				draggedItem.status.folderExpanded
			) {
				if (type === 'resources') {
					itemType = 'resource';
				} else if (type === 'statuses') {
					itemType = 'status';
				}

				// Running async for mobile devices as there was an issue with the DOM not updating without
				$scope.$evalAsync(function () {
					collapseFilter(null, draggedItem, itemType);
				});

				return {
					dragElementMutate: function (element) {
						var caretDownElement =
							element.querySelector('.fa-caret-down');
						if (caretDownElement) {
							caretDownElement.classList.remove('fa-caret-down');
							caretDownElement.classList.add('fa-caret-right');
						}
					},
					onDropCallback: function () {
						collapseFilter(null, draggedItem, itemType);
					},
				};
			}
		}

		function filterItemDropped(
			type,
			startContainerIndex,
			endContainerIndex,
			startIndex,
			endIndex
		) {
			var nestedItems = $scope.calendar[type + 'Nested'];
			var items = seedcodeCalendar.get(type);

			var startContainerItems;
			var endContainerItems;

			//Get item out of nestedItems
			var startItem;
			var endItem;

			//Get item position from full items list
			var startItemPosition;
			var endItemPosition;

			startContainerItems = nestedItems[startContainerIndex].items;
			endContainerItems = nestedItems[endContainerIndex].items;

			// Get item out of nestedItems
			startItem = startContainerItems[startIndex];
			endItem = endContainerItems[endIndex];

			if (!endItem || !startItem) {
				return;
			}

			for (var i = 0; i < items.length; i++) {
				if (startItem.id === items[i].id) {
					startItemPosition = i;
				}
				if (endItem.id === items[i].id) {
					endItemPosition = i;
				}
			}

			if (
				endItemPosition > startItemPosition &&
				endContainerIndex > startContainerIndex
			) {
				endItemPosition--;
			}

			$scope.$evalAsync(function () {
				manageFilters.applyFilterSort(
					type,
					endItemPosition,
					startItemPosition,
					items,
					null,
					null,
					false
				);
			});
		}

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.isChild = function (filterItem, filterList) {
			var parent = filterList.filter(function (value) {
				return value.id === filterItem.folderID;
			});
			return filterItem.folderID && !filterItem.isFolder && parent.length;
		};

		function collapseFilter(e, filterItem, type) {
			if (e) {
				e.stopImmediatePropagation();
			}

			manageFilters.toggleFilterFolder(filterItem, type);

			//emit addScroll event so we adjust the scroll bar when opening and closing the filter folder
			$scope.$emit('addScroll');
		}

		function textFilterLongDelay() {
			var view = seedcodeCalendar.calendar.view;
			if (!view) {
				return false;
			}

			return view.name === 'basicHorizon' && view.getColCnt() >= 120
				? true
				: false;
		}

		function textFilter(keyWord) {
			manageFilters.textFilter(keyWord);
			//update sidebar scope
			// $scope.isFiltered = manageFilters.isGlobalFiltered($scope.filtersKeyword.keyword,$scope.calendar.statuses,$scope.calendar.resources);
			// $scope.$emit('filtersUpdated');
		}

		function resourceListFilter(keyWord, keepSelections) {
			// Function to filter resource list

			manageFilters.applyFilterSearch(
				keyWord,
				'resource',
				!keepSelections,
				true,
				filterComplete
			);

			function filterComplete(filteredResources) {
				console.log('callback');
			}
		}

		$scope.clearTextFilter = clearTextFilter;

		$scope.selectFilter = function (e, filterItem, type) {
			manageFilters.selectFilter(filterItem, type);
		};

		$scope.clearFilters = function () {
			manageFilters.clearFilters();
		};

		$scope.moreFilters = function () {
			var queryID = new Date().getTime(); //Used to create a unique id for our query
			var request = utilities.scriptURL(
				'script=Filters%20From%20WebViewer',
				queryID
			);
		};

		//Clicking on the filter settings gear
		$scope.filterSettings = function (e, filterItem, type, createType) {
			var newFilter;
			e.stopImmediatePropagation();

			if (!filterItem) {
				filterItem = {};
				newFilter = true;
			}

			var config = seedcodeCalendar.get('config');

			if (config.isShare && type !== 'resource') {
				return;
			}

			if (config.preventPopover) {
				config.preventPopover = false;
				return;
			}

			var allowFolders = utilities.getDBKPlatform() !== 'dbkfm';

			var template;

			var filterFieldMap = {
				id: 'id',
				color: 'color',
				name: 'name',
				description: 'description',
				originalName: 'originalName',
				shortName: 'shortName',
				folderID: 'folderID',
				isFolder: 'isFolder',
				sort: 'sort',
				tags: 'tags',
				class: 'class',
				location: 'location',
			};

			var filter = {};
			filter.fieldMap = filterFieldMap;
			filter.type = type;
			filter.allowFolders = allowFolders;
			filter.create = newFilter;

			if (filter.create) {
				// If this is a new filter we want to set defaults and change to panel
				if (createType === 'folder') {
					filterItem.isFolder = true;
				}
				filter.panel = 'edit';
				filter.fade = true;
			}

			filter.item = filterItem;

			filter.edit = manageFilters.createFilterEdit(filterItem);

			if (
				type !== 'resource' &&
				filterItem.name &&
				(!filterItem.status || !filterItem.status.stored)
			) {
				template = 'custom-filter';
			} else if (type === 'status') {
				filter.availableFolders = manageFilters.availableFilterFolders(
					seedcodeCalendar.get('statuses')
				);
				template = filterItem.isFolder
					? 'status-folder'
					: 'status-settings';
			} else if (type === 'resource') {
				filter.availableFolders = manageFilters.availableFilterFolders(
					seedcodeCalendar.get('resources')
				);
				template = 'resource-settings';
			}

			var popover = {
				controller: 'FiltersPopoverCtrl',
				target: e.currentTarget.querySelector('.list-selector-icon')
					? e.currentTarget.querySelector('.list-selector-icon')
					: e.currentTarget,
				container: environment.isPhone
					? $('.filters-popover-container')
					: $('body'),
				type: 'popover', // modal or popover
				destroyOnScroll: environment.isPhone ? false : true,
				direction: 'auto',
				// width: 250,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: filter,
				dataTitle: 'filter',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/sidebar/filters/' +
					template +
					'.html\'"></div>'
			);
		};

		function clearTextFilter() {
			$scope.textFilters = {};
			manageFilters.textFilter('');
			$timeout(function () {
				$scope.textFilters.focus = 'true';
			}, 100);
		}

		function clearFilterListFilter(type) {
			if (type === 'resource') {
				resourceListFilter('', true); // Setting the true parameter here prevents clearing existing resource selections
			}
		}
	}
})();
